<template>
    <div class="page-wrapper">
        <section class="hero" hero-transition-group>
            <div class="title-wrap">
                <img
                    src="@/assets/img/pastille.svg"
                    class="pastille"
                    alt="Houblon Mellön"
                    data-inview="fadeInUp"
                    data-delay="100"
                    width="50"
                    height="66"
                />
                <h1 class="title">
                    <span data-inview="fadeInUp" data-delay="200"><span v-html="contentIsLoaded ? content.hero.titre : '&nbsp;'"></span></span><br />
                    <span data-inview="fadeInUp" data-delay="300"><span v-html="contentIsLoaded ? content.hero.titreLigne2 : '&nbsp;'"></span></span>
                </h1>
            </div>

            <div v-if="contentIsLoaded" class="img-wrap">
                <div class="row">
                    <img
                        :src="content.hero.image1.url"
                        :alt="content.hero.image1.titre"
                        data-inview="slideRight"
                        data-delay="100"
                        width="422"
                    />
                </div>
                <div class="row">
                    <img
                        :src="content.hero.image2.url"
                        :alt="content.hero.image2.titre"
                        data-inview="fadeInDown"
                        data-delay="100"
                        width="422"
                    />
                    <div class="bottom">
                        <img
                            :src="content.hero.image4.url"
                            :alt="content.hero.image4.titre"
                            data-inview="fadeInUp"
                            data-delay="100"
                            width="200"
                        />
                        <img
                            :src="content.hero.image5.url"
                            :alt="content.hero.image5.titre"
                            data-inview="fadeInUp"
                            data-delay="200"
                            width="200"
                        />
                    </div>
                </div>
                <div class="row last">
                    <img
                        :src="content.hero.image3.url"
                        :alt="content.hero.image3.titre"
                        data-inview="slideLeft"
                        data-delay="100"
                        width="422"
                    />
                </div>

                <svg
                    class="corner top-left"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                        fill="#85A785"
                    />
                </svg>
                <svg
                    class="corner top-right"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                        fill="#BEA851"
                    />
                </svg>
                <svg
                    class="corner bottom-left"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                        fill="#BEA851"
                    />
                </svg>
                <svg
                    class="corner bottom-right"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                        fill="#85A785"
                    />
                </svg>
            </div>
        </section>

        <section class="featured-beers" >
            <div class="main-title-wrap">
                <h2 class="title special" data-inview="fadeInUp" data-delay="100"><span v-html="contentIsLoaded ? content.introSimple1.surtitre : '&nbsp;'"></span></h2>
                <svg
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    data-inview="scale"
                    data-delay="100"
                >
                    <path
                        d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                        fill="#BEA851"
                    />
                </svg>
                <h3 class="title" data-inview="fadeInUp" data-delay="200"><span v-html="contentIsLoaded ? content.introSimple1.titre : '&nbsp;'"></span></h3>
            </div>

            <router-link
                :to="item.page.jsonUrl"
                class="beer-wrap"
                data-inview="fadeInUp"
                data-delay="100"
                v-for="(item) in bieresEnVedette" :key="item.page.id"
            >
                <div class="img">
                    <div class="tag" v-if="item.page.nouveaute"><p class="text">{{ $t('Nouveauté') }}</p></div>
                    <div class="types">
                        <div v-if="item.page.surPlace" class="type-tag">{{ $t('Sur place') }}</div>
                        <div v-if="item.page.aEmporter" class="type-tag">{{ $t('À emporter') }}</div>
                    </div>
                    <img width="422" :data-src="item.page.imageSimple.biere.url" class="main-img-beer lazyload" :alt="item.page.imageSimple.biere.titre" />
                </div>
                <div class="infos">
                    <div class="top">
                        <h4 class="title">{{ item.page.titre }}</h4>
                        <p class="text">{{ item.page.sorte }}</p>
                    </div>
                    <div class="bottom">
                        <div class="data">
                            <p class="number">{{ item.page.ibu }}</p>
                            <p class="text">IBU</p>
                        </div>
                        <div class="data">
                            <p class="number">{{ item.page.alcVol }}</p>
                            <p class="text">ALC<span>/VOL</span></p>
                        </div>
                        <div class="arrow">
                            <svg
                                width="25"
                                height="17"
                                viewBox="0 0 25 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M0 7.61401H23.6702V8.91004H0V7.61401Z" fill="#2E3E30" />
                                <path
                                    d="M16.7226 0L25 8.31128L16.7226 16.59L15.7915 15.6588L23.1393 8.31101L15.7915 0.930929L16.7226 0Z"
                                    fill="#2E3E30"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </router-link>

            <a :href="content.lienSimpleUrl1[$route.meta.lang]" class="cta" data-inview="scale" data-delay="100" v-if="contentIsLoaded">
                <p class="text">{{ content.lienSimple1.customText }}</p>
            </a>
        </section>

        <section class="featured-events" v-if="contentIsLoaded">
            <div class="main-title-wrap">
                <h2 class="title special" data-inview="fadeInUp" data-delay="100">{{ content.introSimple2.surtitre }}</h2>
                <svg
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    data-inview="scale"
                    data-delay="100"
                >
                    <path
                        d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                        fill="#BEA851"
                    />
                </svg>
                <h3 class="title" data-inview="fadeInUp" data-delay="200">{{ content.introSimple2.titre }}</h3>
            </div>

            <router-link
                :to="item.page.jsonUrl"
                class="event-wrap"
                data-inview="fadeInUp"
                data-delay="100"
                v-for="(item) in evenementsEnVedette" :key="item.page.id"
            >
                <div class="img">
                    <img width="255" :data-src="item.page.imageSimple.evenement.url" class="main-img-beer lazyload" :alt="item.page.imageSimple.evenement.titre" />
                </div>
                <div class="infos">
                    <div class="name">
                        <h4 class="title">{{ item.page.titre }}</h4>
                    </div>
                    <div class="date">
                        <p class="text">{{ parseDate(item.page.dateDeLevenement.date) }}</p>
                        <div class="arrow">
                            <svg
                                width="25"
                                height="17"
                                viewBox="0 0 25 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M0 7.61401H23.6702V8.91004H0V7.61401Z" fill="#2E3E30" />
                                <path
                                    d="M16.7226 0L25 8.31128L16.7226 16.59L15.7915 15.6588L23.1393 8.31101L15.7915 0.930929L16.7226 0Z"
                                    fill="#2E3E30"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </router-link>

            <div class="full-cta">
                <a :href="content.lienSimpleUrl2[$route.meta.lang]" class="cta" data-inview="scale" data-delay="100">
                    <p class="text">{{ content.lienSimple2.customText }}</p>
                </a>
            </div>
        </section>

        <section class="about-wrap" v-if="contentIsLoaded">
            <div class="img" :style="'background-image: url('+content.imageEtTitres.image.url+')'">
                <div class="main-title-wrap">
                    <h2 class="title special" data-inview="fadeInUp" data-delay="100">{{ content.imageEtTitres.surtitre }}</h2>
                    <svg
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        data-inview="scale"
                        data-delay="100"
                    >
                        <path
                            d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                            fill="#BEA851"
                        />
                    </svg>
                    <h3 class="title" data-inview="fadeInUp" data-delay="200">
                        <span v-html="content.imageEtTitres.titre"></span>
                    </h3>
                </div>
                <a :href="content.imageEtTitres.boutonUrl[$route.meta.lang]" class="cta" data-inview="scale" data-delay="100">
                    <p class="text">{{ content.imageEtTitres.bouton.customText }}</p>
                </a>
            </div>
            <div class="data">
                <h3 class="title" data-inview="fadeInUp" data-delay="200"><span v-html="content.imageEtTitres.titre"></span></h3>
                <a :href="content.imageEtTitres.boutonUrl[$route.meta.lang]" class="cta" data-inview="scale" data-delay="100">
                    <p class="text">{{ content.imageEtTitres.bouton.customText }}</p>
                </a>
            </div>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from 'vuex'

import moment from 'moment'

import { initInViewAnimations } from '@/plugins/InViewAnimations'

export default {
    name: 'Home',

    components: {},

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        initInViewAnimations()
                    }, 1)
                }
            },
            immediate: true,
        },
    },

    computed: {
        bieresEnVedette() {
            if (!this.pageIsLoaded) {
                return []
            }

            if (!this.globals.biereEntry || !this.globals.biereEntry.data || !this.globals.biereEntry.data.length) {
                return []
            }

            if (this.content.bieresMisesDeLavant && this.content.bieresMisesDeLavant.length) {
                return this.globals.biereEntry.data.filter(b => this.content.bieresMisesDeLavant.includes(b.page.id))
            }

            // Return max of 3 items
            return this.globals.biereEntry.data.slice(0, 3)
        },
        evenementsEnVedette() {
            if (!this.pageIsLoaded) {
                return []
            }

            // Return max of 4 items
            return this.globals.evenementEntry.data.filter(ev => ev.page.dateDeLevenement.date >= moment().format('YYYY-MM-DD')).slice(0, 4)
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        parseDate(date) {
            moment.locale(this.$route.meta.lang)
            return moment(date).format('DD MMMM YYYY')
        }
    },
}
</script>
